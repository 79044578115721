import $ from 'jquery';

var initialized = false,
	$basicModal,
	$basicModalActionYes,
	$basicModalActionNo,
	$basicModalHeader,
	$basicModalIcon,
	$basicModalContent;

var defaultConfig = {
	header: 'Header',
	body: 'Body',
	onDeny: function(){
		return false;
	},
	onApprove: function() {
		return true;
	},
	yesString: 'Confirm',
	noString: 'Cancel',
	icon: 'delete icon',
	closable: false
};

var BasicModal = {
	init: function() {
		if (initialized) {
			return;
		}

		$basicModal = $('#basic-modal');
		$basicModalActionYes = $('#basic-modal-action-yes');
		$basicModalActionNo = $('#basic-modal-action-no');
		$basicModalHeader = $('#basic-modal-header');
		$basicModalIcon = $('#basic-modal-icon');
		$basicModalContent = $('#basic-modal-content');

		initialized = true;
	},
	show: function(config) {
		this.init();

		var config = Object.assign({}, defaultConfig, config);

		$basicModalActionYes.html(config.yesString);
		$basicModalActionNo.html(config.noString);
		$basicModalHeader.html(config.header);
		$basicModalIcon.attr('class', config.icon + ' icon');
		$basicModalContent.html(config.body);

		$basicModal.modal({
			closable: config.closable,
			onDeny: config.onDeny,
			onApprove: config.onApprove
		}).modal('show');
	}
};

export default BasicModal;

"use strict";

// @todo - make webroot/src not web-accessible

import appGlobals from './globals';
global.appGlobals = appGlobals;

import $ from 'jquery';
global.$ = global.jQuery = $;

import Clipboard from 'clipboard';
global.Clipboard = Clipboard;

import BasicModal from './Modal/BasicModal.js';
global.BasicModal = BasicModal;

import moment from 'moment-timezone';
moment.tz.setDefault(appGlobals.timezone);
global.moment = moment;

/**
 * global requires
 */

// Core SemanticUI
require('../../libs/semantic-ui/semantic.js');
require('../../libs/trumbowyg/trumbowyg.js');

global.$.trumbowyg.svgPath = '/libs/trumbowyg/ui/icons.svg';

// jQuery-and-semantic friendly datepicker plugin
// https://github.com/mdehoog/Semantic-UI-Calendar
require('../../libs/semantic-ui-calendar/dist/calendar.js');

console.log('Welcome to CourseHorse Admin');

// once page is ready...
$(function(){

    $('.ui.checkbox').checkbox();

    $('.wysiwyg-editor').trumbowyg({
		semantic: false
	});

    // SemanticUI made it difficult to remove or re-initialize a dropdown w/out
    // using the exact same selection; so for now we are using a class to avoid enabling dropdowns()
    $('.select:not(.js-search-select) select, .dropdown')
        .removeAttr('required')
		.dropdown({
			forceSelection: false
		});

    $('.ui.sidebar').sidebar();

    $('.message .close')
        .on('click', function() {
            $(this)
                .closest('.message')
                .slideUp('fast')
            ;
        });

    var $primaryMessage = $('.primary.message');

    // auto fade out the primary message
    if ($primaryMessage.length) {
		setTimeout(function() {
			$primaryMessage.fadeOut();
		}, 5000);
	}

	// set up sticky left column
	$('.ui.sticky')
		.sticky({
			context: '#main-container'
		});

    // auto-clear validation errors after user changes the form field
    $('.field.error input, .field.error select').on('change', function() {
        console.log(this);
    });

    $('.datepicker').calendar({type: 'date'});

    // ability to click to copy
	var clickToCopyEls = document.querySelectorAll('.js-click-to-copy-input');

	$(clickToCopyEls).each(function(i, el) {
		var $self = $(el);

		if ($self.attr('data-clipboard-text') == undefined) {
			$self.attr('data-clipboard-text', $self.find('input').val());
		}

		$self.addClass('ripple');
	});

	var clipboard = new Clipboard(clickToCopyEls);

	$('.datetimepicker').calendar({type: 'datetime'});

	$('.timepicker').calendar({type: 'time'});

	$('button.simulate-loading').on('click', function(e) {
        var $this = $(this);
        var $parentForm = $this.closest('form');

        if ($parentForm.form('is valid')) {
            $this.html('<div class="ui active tiny centered inverted inline loader">&nbsp;</div>');
        }
    });


	// @todo - set this modal handling stuff in a Module
	$('.js-confirm').on('click', function(e) {

		var message = $(this).data('confirm');
		var formName = $(this).data('form-name');

		BasicModal.show({
			header: message,
			body: message,
			onDeny: function(){
				return true;
			},
			onApprove: function($element) {
				$element.addClass('loading');
				document[formName].submit();
				return false;
			},
			yesString: 'Confirm',
			noString: 'Cancel',
			icon: 'delete'
		});

		e.preventDefault();

	});

});
